
import './App.css';



import { Switch, Route, Link } from "react-router-dom";

import Landing from './Homepage/Landing';


import AppartmentBuildingCards from './Appartments/AppartmentBuildingCards';
import Appartments from './Appartments/Appartments';
import AboutUs from './About/AboutUs';
import ContactUs from './Contact/ContactUs';
import Projects from './LandingInForm/LandingInFrom';
import { ProjectProvider } from './Context/ProjcetContext';
import HomeCards from './Homes/HomeCards';
import Homes from './Homes/Homes';
import PublicCards from './PublicBuildings/PublicCards'
import PublicBuilding from './PublicBuildings/PublicBuilding'
import IndustrialCards from './Industrial/IndustrialCards'
import Industrial from './Industrial/Industrial'
import UrbanCards from './UrbanPlan/UrbanCards'
import UrbanPlan from './UrbanPlan/UrbanPlan'
import InfraCards from './Infrastructural/InfraCards';
import Infrastructural from './Infrastructural/Infrastructural';
import NavbarNav from './Nav/NavbarNav';
import ErrorComp from './ErrorComp/ErrorComp'


function App() {
  return (
    <>
      <ProjectProvider>

        <div className="App">
         
          <NavbarNav ></NavbarNav>

          <Route exact path='/about'><AboutUs /></Route>
          <Route exact path='/contact' > <ContactUs /> </Route>
          <Route exact path='/'> <Landing    /></Route>
     

          <Route exact path='/homecards'> <HomeCards></HomeCards> </Route>
          <Route exact path='/homes/:id' > <Homes></Homes>  </Route>


          <Route exact path='/appartmentbuildingcards'><AppartmentBuildingCards /></Route>
          <Route exact path='/appartments/:id'><Appartments /></Route>

          <Route exact path='/publiccards' > <PublicCards></PublicCards> </Route>
          <Route exact path='/publicbuiling/:id' ><PublicBuilding></PublicBuilding></Route>

          <Route exact path='/industrialcards' > <IndustrialCards></IndustrialCards> </Route>
          <Route exact path ='/industrial/:id' > <Industrial></Industrial> </Route>

          <Route exact path='/urbancards' > <UrbanCards></UrbanCards> </Route>
          <Route exact path ='/urbanplan/:id' > <UrbanPlan></UrbanPlan> </Route>


          
          <Route exact path='/infracards' >  <InfraCards></InfraCards> </Route>
          <Route exact path ='/infrastructural/:id' > <Infrastructural></Infrastructural> </Route>

          <Route exact path='/projects' >  <Projects />  </Route>

          <Route exact path ='/error'><ErrorComp/></Route>
        </div>
      </ProjectProvider>
    </>
  );
}

export default App;
