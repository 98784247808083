import React from "react";
import CompleteHook from "./Counter";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Roze from "./roze.jpg";
import Lidija from './lidija.jpg'
import Marjan from './marjan.jpg'
import Gordana from './gordana.jpg'

import "./AboutUs.css";

function AboutUs() {
  return (
    <div className="AboutUs text-center AboutUsBcg  ">
      <div className="AboutUsLeft">
        <div className="whoWeAre">
          <h2>ЗА НАС</h2>
          <p>
            “БИРО ШУКУРОСКИ“ е проектантска фирма во која основната цел е
            индивидуалниот пристап и уникатност на секој проект со примена на
            најсовремени проектантски и изведбени методи како и модерен
            архитектонски израз во дизајнерскиот пристап. При изработката на
            проектите се трудиме да создадеме комплексна но едноставна
            архитектура, односно логична и функционална, а истовремено естетски
            возбудлива. Фирмата е основана во 2008 година и се занимава со
            проектирање, надзор, инженеринг, консалтинг и ревизија на проектна
            докуметнација. Како архитектонска фирма располагаме со разновиден
            спектар на професии вклучувајќи : архитекти, градежни инженери,
            машински инженери, електро инженери, внатрешни дизајнери, проектни
            менаџери од кои дел се вработени во фирмата, а дел се појавуваат
            како надворешни соработници. Во изминатиов период имаме постигнато
            многу добри резултати во делот на надзор и консултантски услуги во
            градежната област, како и во делот за изработка на урбанистички
            проекти.
          </p>
        </div>

        <CompleteHook />

        <div className="whoWeAre">
          <h2>УСЛУГИ</h2>
          <p>
            Изработка на проекти (со сите потребни фази), елаборати и студии,
            потребни за добивање одобрениe за градење и друга неопходна
            документација, реконструкциja, реновирањe и адаптациja на сите
            видови објекти, ентериерно уредување, урбанистички проекти,
            проектантски надзор при изведба и 3D приказ на објекти.
          </p>

          <div className="services-icons">
            <div className="services-inner">
              {" "}
              <p>ПРОЕКТИРАЊЕ</p>{" "}
            </div>
            <div className="services-inner">
              {" "}
              <p>НАДЗОР</p>{" "}
            </div>
            <div className="services-inner">
              {" "}
              <p>РЕВИЗИЈА</p>{" "}
            </div>
            <div className="services-inner">
              {" "}
              <p>3D МОДЕЛИРАЊЕ</p>{" "}
            </div>
            {/* <div className="services-inner">  <p>ПРОЕКТИРАЊЕ</p>  </div> */}
            {/* <div className="services-inner">  <p>ПРОЕКТИРАЊЕ</p>  </div> */}
          </div>
        </div>

        <div className="text-center bold">
          <Link to="/projects">
            <Button variant="danger" size="lg" className="toprojects">
              ВИДИ ПРОЕКТИ
            </Button>
          </Link>
        </div>
      </div>

      <div className="AboutUsRight  text-center ">
        <h2>ЗАПОЗНАЈТЕ ГО ТИМОТ</h2>
        <div className="meet-the-team">
          <div >


           <div className="team-flex">
           <div >
              <img src={Marjan} alt="" />
              <h4>Марјан Шукуроски</h4>
              <h5> дипл. инж. архитект </h5>
              <p>Архитект со 26 годишно работно искуство во областа на проектирање, ревизија и надзор. Главен проектант и планер на сите архитектонски и урбанистички проекти.</p>

             
            </div>
            <div >
              <img src={Lidija} alt="" />
              <h4>Лидија Шукуроска</h4>
              <h5> градежен инженер </h5>
              <p>Инженер со 19 годишно работно искуство, во областа на архитектурата, од кои последните 5 години се во областа на урбанизмот.  </p>

             
            </div>
            
           </div>




            <div className="team-flex">
            <div  >
                <img src={Gordana} alt="" />
                <h4>Гордана Филовска</h4>
                <h5> дипл. инж. архитект </h5>
                <p>Архитект со 21 годишно работно искуство во областа на архитектонско проектирање и изработка на елаборати за енергетска ефикасност. </p>
              </div>
              <div >
                <img src={Roze} alt="" />
                <h4>Розе       Стојаноска</h4>
                <h5> архитектонски техничар </h5>
                <p>Архитектонски техничар со 26 години работно искуство, чија главна задача е техничка обработка на сите проекти.</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
