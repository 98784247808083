import React, { Component } from "react";
import { data } from "./datahomes";
import "../App.css";
import { Link } from "react-router-dom";



class HomeCards extends Component {
  render() {
    return (
 <div className='cards-wrapper-main'>
   <div className='cards-main-title'>
   <h2>ДОМУВАЊЕ ВО СТАНБЕНИ КУЌИ</h2>
   </div>
       <div className='flex-main-cards bcggray'>
      
      <div className='flexCards'>

      {data.map((appartmentDetail, index) => {
        return (
          <>
            <Link
              to={  '/homes/' + appartmentDetail.id ? "/homes/" +  appartmentDetail.id : "/error" } 
              className="grid containerbsh"
            >
              
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img src={appartmentDetail.img} alt="" className="img" />
                  </div>

                  <div className="flip-card-back">
                  <div className="text-back">
                    <div > {appartmentDetail.name} </div>
                    <div >{appartmentDetail.desc}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </>
        );
      })}
    </div>
   </div>
 </div>
    );
  }
}

export default HomeCards;
