import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useRef } from "react";
import emailjs from 'emailjs-com';


function FormContact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm(`${process.env.REACT_APP_SERVICE_ID}`, `${process.env.REACT_APP_TEMPLATE_ID}`, form.current, `${process.env.REACT_APP_PUBLIC_KEY}`)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <React.Fragment>
      <div className="form-wrapped">
        <Form className="form-bsh" ref={form} onSubmit={sendEmail}>
          <div className="titles-contact">
            <h3>Контактирајте не доколку имате било какви прашања</h3>
            <h5>
              Прашањата ќе Ви бидат одговорени во најкратко можно време. Ве очекуваме!
            </h5>
          </div>

          <div className="icons">
            <i class="fas fa-map-marked-alt fa-lg"><p>адреса: "БУЛЕВАР ЈАНЕ САНДАНСКИ" бб - Прилеп </p></i>  
            {/* / БИЗНИС ЦЕНТАР КАТ 2 ЛОКАЛ 100 */}
            <i class="fas fa-clock fa-lg"><p>работно време : 08-16 h</p></i>
            <i class="fas fa-phone-alt fa-lg"><p>контакт-телефон : +389/48/ 411-758</p> </i>
            <i class="fas fa-mobile-alt fa-lg"> <p>контакт-мобилен : +389/70/ 829-906 </p></i>
          </div>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Вашата порака</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>


          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label> Вашето Име и Презиме</Form.Label>
            <Form.Control type="name" placeholder="име и презиме" />
          </Form.Group>


          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label> Вашата email адреса</Form.Label>
            <Form.Control type="email" placeholder="example@example.com" />
          </Form.Group>

         

          <Button type="submit" variant="light" block className='bcgblue' style={{color: "#120d5e"  }}  >
            Submit
          </Button>
        </Form>
      </div>
     
    </React.Fragment>
  );
}

export default FormContact;
