import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ".././App.css";
import { ProjectContext } from "../Context/ProjcetContext";

const Ul = styled.ul`
  @media (max-width: 426px) {
    flex-flow: column nowrap;
    background-color: rgba(255, 255, 255, 0.57);

  
    position: fixed;
 
    transform: ${({ open }) => (open ? "translateX(50)" : "translateX(100%)")} } ;
    
    top: 0;
    right: 0;
    height: 20vh;
    width: 425px;
    padding-top: 2.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 20;
  }
`;



const RightNav = () => {

  // const [open, setOpen] = useState(false);
  const {open,setOpen} = useContext(ProjectContext)

  return (
    <Ul open={open} className="new-nav">
      <ul>
        <li>
          <Link onClick={() => setOpen(false)} to="/about" className="fromLeft">
          ЗА НАС
          </Link>
        </li>
        <li>
          <Link onClick={() => setOpen(false)} to="/contact" className="fromLeft">
            КОНТАКТ
          </Link>
        </li>
      </ul>
    </Ul>
  );
};

export default RightNav;
