export const data = [
    {
        id: 1,
        name: "СТАНБЕНА ЗГРАДА СО ДЕЛОВЕН ПРОСТОР ВО ПРИЗЕМЈЕ ",
        desc: "ОСНОВЕН ПРОЕКТ",
        img: ("../../images/photos11/cover11.png"),
    },

    {
        id: 2,
        name: "СТАНБЕНО ДЕЛОВЕН ОБЈЕКТ ",
        desc: "ПРОЕКТ ЗА ИЗМЕНИ ВО ТЕК НА ГРАДБА И ПРОЕКТ НА ИЗВЕДЕНА СОСТОЈБА",
        img: "../../images/photos15/cover15.png",
    },

  

]



export const projectsData = {


    1: {
        title: "СТАНБЕНА ЗГРАДА СО ДЕЛОВЕН ПРОСТОР ВО ПРИЗЕМЈЕ",
        subtitle: "ОСНОВЕН ПРОЕКТ",
        description: "Станбено деловниот објект е изведен во централното градско подрачје на општина Прилеп. Објектот е со бруто површина од 1800m2. Асиметрична основа со станови со различна големина со совршено симетричен пропорционален облик. Расчленувањето на фасадата формира естетски пропорционалини полиња кои на објектот му даваат специфичен изглед. Во приземниот дел се предвидени трговско деловен простор, санитарен јазол и магацински простор. Врската на приземјето со катовите и подкровјето не се планира односно деловниот простор представува засебна целина. За станбениот дел од објектот на секој кат предвидени се по три станбени единици два двособни и еден трособен стан, додека во поткровјето се предвидени два трособни стана. Заедничката вертикална комуникација е предвидена централно во објектот. Поткровјето е повлечено во однос на катовите со максимален агол на косина од 600а потоа продолжува со наклон од 20% така што вертикалната рамнина во пресек со теренот се поклопува со градежната линија. ",
        images: [
            {
                image: "../../images/photos11/11 osnova 1.png"
            },
            {
                image: "../../images/photos11/11 osnova 2.png"
            },
            {
                image: "../../images/photos11/11 presek 1.png"
            },
            {
                image: "../../images/photos11/11 presek 2.png"
            },
            {
                image: "../../images/photos11/11 pogled 1.png"
            },
            {
                image: "../../images/photos11/11 pogled 2.png"
            },
            {
                image: "../../images/photos11/11 pogled 3.png"
            },

        ]
    },



    2: {
        title: "СТАНБЕНО ДЕЛОВЕН ОБЈЕКТ",
        subtitle: "ПРОЕКТ ЗА ИЗМЕНИ ВО ТЕК НА ГРАДБА И ПРОЕКТ НА ИЗВЕДЕНА СОСТОЈБА",
        description: "При изведбата на станбено деловниот објект констатирани се одредени проблеми при што е пристапено кон изработка на Проект за измени во тек на градба а после добиеното Решение за измени во тек на градба изработен е и Проект на изведена состојба согласно законските норми. По барање на инвеститорот, од првично прдвидените два стана на ниво на поткровјето, се оформуваат четири станбени единици. Исто така на ниво на подкровје, задржана е вертикалната равнина на градежната линија, односно нема исфрлања поголеми од дозволените, согласно Правилникот за стандарди и нормативи за урбанистичко планирање. Со овие промени се зголемува корисната површина на поголем дел од станбените единици, па од првично предвидени ходници и проодни или непроодни тераси, сега се добиваат корисни површини. Вкупниот број на станбени единици (30) е ист  и во Основниот проект и во Проектот за измени во тек на градба. Исто така не се предвидени измени во поглед на типот на кровната конструкција. ",
        images: [
            {
                image: "../../images/photos15/osnova 1.png"
            },
            {
                image: "../../images/photos15/osnova 2.png"
            },
            {
                image: "../../images/photos15/presek 1.png"
            },
            {
                image: "../../images/photos15/presek 2.png"
            },
            {
                image: "../../images/photos15/izgled 1.png"
            },
            {
                image: "../../images/photos15/izgled 2.png"
            },


        ]
    },

    


}