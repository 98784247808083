export const data = [
    {
        id: 1,
        name: "СТАНБЕНА КУЌА  ",
        desc: "ОСНОВЕН ПРОЕКТ",
        img: "../../images/photos1/cover1.png",
    },

    {
        id: 2,
        name: "СТАНБЕНА КУЌА ",
        desc: "ОСНОВЕН ПРОЕКТ",
        img: ("../../images/photos2/cover2.png"),
    },

    {
        id: 3,
        name: "СТАНБЕНА КУЌА СО ДЕЛОВЕН ПРОСТОР ",
        desc: "ОСНОВЕН ПРОЕКТ",
        img: ("../../images/photos3/cover3.png"),
    },

    {
        id: 4,
        name: "СТАНБЕНА КУЌА  ",
        desc: "ОСНОВЕН ПРОЕКТ",
        img: ("../../images/photos6/cover6.png"),
    },

    {
        id: 5,
        name: "СТАНБЕНА КУЌА  ",
        desc: "ОСНОВЕН ПРОЕКТ",
        img: ("../../images/photos7/cover7.png"),
    },


    

]



export const projectsData = {


    1: {
        title: "СТАНБЕНА КУЌА  ",
        subtitle: "ОСНОВЕН ПРОЕКТ",
        description: "   Станбена куќа во фаза на градба во село Дебреште општина Долнени. Предвидена површина на објектот 276m2. Проектот предвидува јасно функционално и обликувано решение со избалансирана конструкција во согласност со желбите и потребите на инвеститорот.",
        images: [
            {
                image: "../../images/photos1/1 osnova 1.png"
            },
            {
                image: "../../images/photos1/1 osnova 2.png"
            }, {
                image: "../../images/photos1/1 presek 1.png"
            }, {
                image: "../../images/photos1/1 pogled1.png"
            },

            {
                image: "../../images/photos1/1 pogled 2.png"
            },
            {
                image: "../../images/photos1/1 pogled 3.png"
            },
            {
                image: "../../images/photos1/1 pogled 4.png"
            },

        ]
    },



    2: {
        title: "СТАНБЕНА КУЌА ",
        subtitle: 'ОСНОВЕН ПРОЕКТ',
        description: 'Станбена куќа во фаза на градба во Прилеп во населба Рид. Предвидена површина на објектот 380m2. Хармонично и интересно решение со квалитетна комбинација на нашето искуство и желбите на инвеститорот, сè со цел да се постигне идеална рамнотежа помеѓу потребите и можности.  Објектот е предвиден како скелетен систем на градба со армирано бетонски столбови и греди и ѕидарија од керамички блокови.Приземјето на објектот е предвидено за деловен простор додека на катот и во поткровјето јасно се издвоени дневниот престој и просторот за одмор. ',

        images: [

            {
                image: "../../images/photos2/2 osnova 1.png"
            },
            {
                image: "../../images/photos2/2 osnova 2.png"
            }, {
                image: "../../images/photos2/2 osnova 3.png"
            },
            {
                image: "../../images/photos2/2 presek1.png"
            },

            {
                image: "../../images/photos2/2 presek2.png"
            },

            {
                image: "../../images/photos2/2 pogled 1.png"
            },

            {
                image: "../../images/photos2/2 pogled 2.png"
            },

            {
                image: "../../images/photos2/2 pogled 3.png"
            },

        ]
    },
    3: {
        title: "СТАНБЕНА КУЌА СО ДЕЛОВЕН ПРОСТОР ",
        subtitle: 'ОСНОВЕН ПРОЕКТ',
        description: 'Станбена куќа изградена во село Обршани општина Кривогаштани. Предвидена површина на објектот 261m2. Објектот представува простудирана волуменска експресија со декоративен пристап на второстепената пластика со јасна конструктивна логика и прочистен едноставен модерен израз. Во приземјето е сместен деловен простор додека на катот просторот е функционално изведен за потребите на едно четиричлено семејство. Објектот е изведен со скелетен систем на градба и контактно изолациона фасада.При изведбата особено се нагласени влезовите во деловниот и во станбениот дел од објектот, како и брисолеите над влезот во делот над терасата.',

        images: [

            {
                image: "../../images/photos3/3osnova1.png"
            },
            {
                image: "../../images/photos3/3osnova2.png"
            },
            {
                image: "../../images/photos3/3 presek 1.png"
            },



            {
                image: "../../images/photos3/3 pogled1.png"
            },

            {
                image: "../../images/photos3/3 pogled 2.png"
            },

            {
                image: "../../images/photos3/3 pogled 3.png"
            },

        ]
    },

    4: {
        title: "СТАНБЕНА КУЌА ",
        subtitle: 'ОСНОВЕН ПРОЕКТ',
        description: 'Квалитетно, хармонично и интересно решение при што е постигната идеална рамнотежа помеѓу желбите на инвеститорот и можностите со добро избалансирани волумени. Предвиден е скелетен систем на градба со армирано бетонски столбови и греди, со скриени кровни површини уоквирени со атика кои даваат современ изглед на објектот. Приземниот дел од објектот е во функција на дневниот престој додека врската со катот кој е наменет за одмор е обезбедена со заоблено впечатливо скалишно јадро видливо во просторот. Станбена куќа изградена во село Дебреште општина Долнени. Предвидена површина на објектот 172m2. ',

        images: [

            {
                image: "../../images/photos6/6 osnova 1.png"
            },
            {
                image: "../../images/photos6/6 osnova 2.png"
            },
            {
                image: "../../images/photos6/6 presek 1.png"
            },



            {
                image: "../../images/photos6/6 pogled 1.png"
            },

            {
                image: "../../images/photos6/6 pogled 2.png"
            },




        ]
    },

    5: {
        title: "СТАНБЕНА КУЌА ",
        subtitle: 'ОСНОВЕН ПРОЕКТ',
        description: 'Станбена куќа во фаза на градба во Прилеп во населба Рид. Предвидена површина на објектот 380m2.Хармонично и интересно решение со квалитетна комбинација на нашето искуство и желбите на инвеститорот, сè со цел да се постигне идеална рамнотежа помеѓу потребите и можности. Објектот е предвиден како скелетен систем на градба со армирано бетонски столбови и греди и ѕидарија од керамички блокови. Приземјето на објектот е предвидено за деловен простор додека на катот и во поткровјето јасно се издвоени дневниот престој и просторот за одмор.',

        images: [

            {
                image: "../../images/photos7/7 osnova 1.png"
            },
            {
                image: "../../images/photos7/7 osnova 2.png"
            },
            {
                image: "../../images/photos7/7 presek 1.png"
            },
            {
                image: "../../images/photos7/7 presek 2.png"
            },



            {
                image: "../../images/photos7/7 pogled 1.png"
            },

            {
                image: "../../images/photos7/7 pogled 2.png"
            },



        ]
    },

    
}