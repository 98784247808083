import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import { Link } from "react-router-dom";
import logoBSH from "./logo-negative.png";
import ".././App.css";





const NavbarNav = () => {
  return (
    <div className="navbarbsh">
      <div className="logo ">
      <Link to="/" >
             <div >
             <img src={logoBSH} alt="logo" className="logobsh" />
             </div>
            </Link>
      </div>
      <Burger />
    </div>
  )
}

export default NavbarNav
