import React from "react";
import { Link } from "react-router-dom";
import './LandingInForm.css'

function Projects() {
  return (
    <div className="Projects flexProjects ">
      <div>
        <Link to="/homecards" className="">
          <div className="projcestsHomes flexProjects">
            <p>
              Домување во <br /> станбени куќи
            </p>
          </div>
        </Link>

       
        <Link to="/appartmentbuildingcards">
          <div className="projcestsAppartments flexProjects">
            <p>
              {" "}
              Домување во <br /> станбени згради
            </p>
          </div>
        </Link>
      </div>
      <div>
        {" "}
        <Link to='/publiccards'>
          <div className="projcestsPubilc flexProjects">
            <p>Јавни објекти</p>
          </div>
        </Link>
        <Link to='/industrialcards'>
          <div className="projcestsIndustrial flexProjects">
            <p>Индустриски објекти</p>
          </div>
        </Link>
      </div>
      <div>
        {" "}
        <Link to='/infracards'>
          <div className="projcestsInfra flexProjects">
            <p>Инфраструктурни објекти</p>
          </div>
        </Link>
        <Link to='/urbancards'>
          <div className="projcestsUrb flexProjects">
            <p>Урбанистички планови</p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Projects;
