

import React, { useState } from "react";
import ReactMapGL, {Marker} from "react-map-gl";
import Location from './location.svg'
import FormContact from './Form';
import './Contact.css'



function ContactUs() {
  const [viewport, SetWiewport] = useState({
    width: "100vw",
    height: "89vh",
    latitude: 41.344893394758756,
    longitude: 21.552689246287912,
    zoom: 17,
    pitch:45,

    
  });
  const MapToken = process.env.REACT_APP_MAPBOX_TOKEN;
  return <div className='contact-wrapped'>
      <FormContact/>
      <ReactMapGL {... viewport} mapboxApiAccessToken={MapToken}
      mapStyle = 'mapbox://styles/marijashukuroska/ckib0t2tq02y119pj1hwtpi66'
      onViewportChange = {viewport => SetWiewport(viewport)}
      className='map-width' >

            <Marker latitude={ 41.34504246994608 } longitude={21.552700444055006} >
                <button className='marker-bsh'><img src={Location}alt=""/> <p>Биро Шукуроски</p></button>
            </Marker>
      </ReactMapGL>
  </div>;
}

export default ContactUs;
