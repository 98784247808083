import React, { Component, useContext, useEffect } from "react";

import Carousel from "react-bootstrap/Carousel";
import ControlledCarousel from "./ControlledCarousel";
import { useParams } from "react-router-dom";
import { projectsData } from "./datahomes";
import { ProjectContext } from "../Context/ProjcetContext";

export default function Homes() {
  let { id } = useParams();

  let curProject = projectsData[id];
  return (
    <div className='wrapper-third-component'    >
      <div className="left-wrapper">
        <h1>{curProject.title}</h1>

        <h2>{curProject.subtitle}</h2>
        <p>{curProject.description}</p>
      </div>

      <div className="right-wrapper">
        <ControlledCarousel images={curProject.images} />
      </div>
    </div>
  );
}
