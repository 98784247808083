import React, { createContext, useEffect, useState } from "react";
export const ProjectContext = createContext();

export const ProjectProvider = (props) => {

    const [shownItem, setShownItem] = useState({})

    const [open,setOpen]=useState(false)
 
  const sharedValues = {
   shownItem,
   setShownItem,
   open,setOpen
  };

  return (
    <ProjectContext.Provider value={sharedValues}>
      {props.children}
    </ProjectContext.Provider>
  );
};
