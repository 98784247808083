import React, { Component } from 'react';

class ErrorComp extends Component {
    render() {
        return (
            <div>
                hi
            </div>
        );
    }
}

export default ErrorComp;