import React, { Component, useContext, useEffect, useState } from "react";


import Carousel from "react-bootstrap/Carousel";
import { ProjectContext } from "../Context/ProjcetContext";





function ControlledCarousel({ images }) {
  const { shownItem } = useContext(ProjectContext)
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);


  };


  return (
    <div>
      <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>
        {images && images.map((el) => {
          return (
            <Carousel.Item interval={1000000} >

              <img src={el.image} alt='' className='imgdummy' />
            </Carousel.Item>)
        })}


      </Carousel>
    </div>

  );
}




export default ControlledCarousel

