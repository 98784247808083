

import CountUp from "react-countup";

const CompleteHook = () => {
  //
  return (
    <div className="counter-projcets">
      <div className="counter-projcet">
         <CountUp end={266} duration={3.5} useEasing={false}  > </CountUp>
         <p>АРХИТЕКТОНСКИ ПРОЕКТИ</p>
      </div>

      <div className="counter-projcet">
        <CountUp end={48} duration={3.5} useEasing={false}></CountUp>
        <p>УРБАНИСТИЧКИ ПРОЕКТИ</p>
      </div>

      <div className="counter-projcet">
        <CountUp end={332} duration={3.5} useEasing={false}></CountUp>
        <p>ЗАДОВОЛНИ КЛИЕНТИ</p>
      </div>
    </div>

    
  );
};

export default CompleteHook;
