import React, { Component } from "react";
import { data } from "./datainfra";
import "../App.css";
import { Link } from "react-router-dom";

class InfraCards extends Component {
  render() {
    return (
    <div>
      <div className="cards-main-title">
          <h2>ИНФРАСТРУКТУРНИ ОБЈЕКТИ</h2>
        </div>
       <div className='flex-main-cards'>
        <div className='flexCards'>
        {data.map((appartmentDetail, index) => {
          return (
            <>
              <Link
                to={"/infrastructural/" + appartmentDetail.id}
                className="grid containerbsh"
              >
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img src={appartmentDetail.img} alt="" className="img" />
                    </div>

                    <div className="flip-card-back">
                          <div className="text-back">
                            <div> {appartmentDetail.name} </div>
                            <div>{appartmentDetail.desc}</div>
                          </div>
                        </div>
                  </div>
                </div>
              </Link>
            </>
          );
        })}
      </div>
     </div>
    </div>
    );
  }
}

export default InfraCards;
