import '.././App.css';
// import React from 'react'
import { Link } from "react-router-dom";
import Pic from './herof.png';
import LandingInForm from '../LandingInForm/LandingInFrom'

function Landing() {
  return (
    <div>
      <div className='landing hero-main-desktop'>

        <img src={Pic} alt='' className='picture' />


        <div className='building' >
          <Link to="/appartmentbuildingcards">
            <h4 className='build animate__animated animate__pulse animate__slower animate__infinite' > Домување во станбени згради</h4>
            <button className='circle'></button>
          </Link>
        </div>


        <div className='housing '>
          <Link to="/homecards">
            <h4 className='home animate__animated animate__pulse animate__slower animate__infinite'> Домување во станбени куќи</h4>
            <button className='circle'></button>
          </Link>
        </div>


        <div className='public'>
          <Link to="/publiccards">
            <h4 className='publicObj animate__animated animate__pulse animate__slower animate__infinite'> Општествени објекти</h4>
            <button className='circle'></button>
          </Link>
        </div>


        <div className='urb'>
          <Link to="/urbancards">
            <h4 className='urbanism animate__animated animate__pulse animate__slower animate__infinite'> Урбанистички планови</h4>
            <button className='circle'></button>
          </Link>
        </div>


        <div className='ind'>
          <Link to="/industrialcards">
            <h4 className='industrial animate__animated animate__pulse animate__slower animate__infinite'> Индустриски објекти</h4>
            <button className='circle'></button>
          </Link>
        </div>


        <div className='infra'>
          <Link to="/infracards">
            <h4 className='infrastructure animate__animated animate__pulse animate__slower animate__infinite'> Инфраструктурни објекти</h4>
            <button className='circle'></button>
          </Link>
        </div>
      </div>

      <div className='landing-in-form'>
            <LandingInForm></LandingInForm>
          </div>
    </div>
  )
}

export default Landing

